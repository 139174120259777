
import { Vue, Options, prop } from "vue-class-component";
import { MainAppController } from "../../controllers/MainAppController";
import Checkbox from "primevue/checkbox";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

class Props {
  modelValue = prop<boolean>({
    required: true
  });
  data = prop<Array<any>>({
    default: [],
    type: Array
  });
  maxHeight = prop<string>({
    default: "",
    type: String
  });
  isCustom = prop<boolean>({
    default: false,
    type: Boolean
  });
  single = prop<boolean>({
    default: false,
    type: Boolean
  });
  image = prop<string>({
    default: "",
    type: String
  });
  isTerm = prop<boolean>({
    default: true,
    type: Boolean
  });
}
@Options({
  name: "PreviewImage",
  emits: ["update:modelValue", "close"],
  components: {
    Checkbox
  }
})
export default class extends Vue.with(Props) {
  onClose() {
    this.$emit("update:modelValue", false);
    this.$emit("close");
    this.clickCloseForGTM();
  }
  get maxHeightDefault() {
    return (5 / 6) * MainAppController.screenSize.height;
  }

  get newData() {
    const temp: string[] = this.data;

    if (this.isCustom) {
      temp.unshift("");
      return temp;
    }
    return temp.map((item: any) => {
      const formatSplited = item.url.split(".")
      return {
        image: item.image,
        url: item.url,
        type: formatSplited[formatSplited.length - 1]
      }
    })
  }
  formatSourceImage(image: string) {
    if (image.includes("http")) {
      return image;
    }
    return require(`@/app/ui/assets/images/${image}`);
  }

  onClickImage(url: string) {
    if (url) {
      url = url.includes("http") ? url : `https://${url}`;
      window.open(url, "_blank");
    }
  }

  isRead = false;
  clickCheckBoxForGTM() {
    dataLayer("pop_up_banner_centang", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }

  clickCloseForGTM() {
    dataLayer("pop_up_banner_close", {}, [
      "timestamp",
      "userType",
      "userId"
    ]);
  }
}
